@media (min-width: 1920px) and (max-width: 1925px){}
@media screen and (min-width: 1920px){}
@media screen and (min-width: 2560px){}
@media (min-width: 1680px) and (max-width: 1681px){}
@media (min-width: 1366px) and (max-width: 1367px){

}
@media (min-width: 1280px) and (max-width: 1281px){

}
@media (min-width: 992px) and (max-width: 1279px){

}
@media (min-width: 801px) and (max-width: 991.2px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 33.3%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 320px !important;
        position: relative !important;
    }
    .v7d78o{
        width: 100%!important;
        text-align: center!important;
    }
    .eg5468{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 310px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 32px!important;
        line-height: 40px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x{
        margin: 16px 0 0 0!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 33.3%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 21px!important;
        line-height: 30px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 33.3%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 155px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media (min-width: 768px) and (max-width: 800px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 33.3%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 320px !important;
        position: relative !important;
    }
    .v7d78o{
        width: 100%!important;
        text-align: center!important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 310px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 32px!important;
        line-height: 40px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x{
        margin: 16px 0 0 0!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 33.3%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 21px!important;
        line-height: 30px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 33.3%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 155px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (max-width: 767px){
    .s288ei .o747gy .s40258 .uf9065 .c137ai{
        text-align: center!important;
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        text-align: center!important;
        width: 100%!important;
    }
    .p563v7{
        margin: 0!important
    }
    .a56651{
        font-size: 17px!important;
        text-align: center!important;
    }
    .j99di5{
        font-size: 14px!important;
        text-align: center!important;
    }
    .a3343b{
        font-size: 14px!important;
        text-align: center!important;
    }
    .j1w74y iframe{
        width: 100%!important;
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        width: 100%!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        width: 100%!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        padding: 24px 0!important;
    }
    .s288ei .x195er .fu22h2:nth-child(4) .y931x3{
        background: #FFC786!important;
    }
    .s288ei .x195er .fu22h2:nth-child(5) .y931x3{
        background: #FFFFFF!important;
    }
    .s288ei .x195er .fu22h2:nth-child(8) .y931x3{
        background: #FFC786!important;
    }
    .s288ei .x195er .fu22h2:nth-child(9) .y931x3{
        background: #FFFFFF!important;
    }
    .o344kj{
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
        margin: 0 0 12px 0!important;
    }
    .r3q378{
        max-width: 86px!important;
        height: 86px!important;
    }
}
@media screen and (min-width:600px) and (max-width:767px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 33.3%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 320px !important;
        position: relative !important;
    }
    .v7d78o{
        width: 100%!important;
        text-align: center!important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 310px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 32px!important;
        line-height: 40px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x{
        margin: 16px 0 0 0!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 33.3%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 21px!important;
        line-height: 30px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 33.3%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 155px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:768px) and (max-height:360px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 33.3%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 320px !important;
        position: relative !important;
    }
    .v7d78o{
        width: 100%!important;
        text-align: center!important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 310px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 32px!important;
        line-height: 40px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x{
        margin: 16px 0 0 0!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 33.3%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 21px!important;
        line-height: 30px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 33.3%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 155px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:500px) and (max-width:599px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 33.3%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 320px !important;
        position: relative !important;
    }
    .v7d78o{
        width: 100%!important;
        text-align: center!important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 310px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 32px!important;
        line-height: 40px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x{
        margin: 16px 0 0 0!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 33.3%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 21px!important;
        line-height: 30px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 33.3%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 139px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:480px) and (max-width:499px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 210px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 260px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 22px!important;
        line-height: 30px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 18.5% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 208px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:416px) and (max-width:479px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 210px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 240px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 22px!important;
        line-height: 30px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 21% 5% !important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 176px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:413px) and (max-width:415px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 210px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 225px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 24px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 21% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 176px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:405px) and (max-width:412px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 235px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 20px!important;
        line-height: 28px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 22% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 174px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:395px) and (max-width:404px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 235px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 20px!important;
        line-height: 28px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 168px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:392px) and (max-width:394px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 235px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 23px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 25% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 165px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:390px) and (max-width:391px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 200px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 24px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 22% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 164px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:381px) and (max-width:389px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 200px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 24px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 22% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 160px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:375px) and (max-width:380px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 200px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 24px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 22% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 156px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:359px) and (max-width:374px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 200px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 220px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 18px!important;
        line-height: 24px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 22% 5%!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 14px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 14px!important;
        line-height: 23px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 18px!important;
        line-height: 24px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 148px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 15px!important;
        line-height: 23px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (min-width:321px) and (max-width:358px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 130px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 170px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 16px!important;
        line-height: 22px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 42px 32px!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 14px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 13px!important;
        line-height: 22px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 16px!important;
        line-height: 22px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 142px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 14px!important;
        line-height: 22px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
}
@media screen and (max-width: 320px){
    .mq4603{
        display: none!important;
    }
    .uvx453{
        display: block!important;
    }
    .g69652 .w6v717 .z8j92s .fb596t li a svg{
        width: 15px;
        height: 15px;
    }
    .g69652 .w6v717, .z8j92s, .fb596t{
        height: 80px!important;
    }
    .g69652 .v80y78 .col-md-9 .col-md-3{ 
        width: 50%!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        margin: 12px 0 0 0!important;
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .opq677 li a{
        text-align: center!important;
    }
    .g69652 .v80y78 .y131cy .a93n9n{
        opacity: 100%!important
    }
    .azw915{
        height: auto!important;
        padding: 12px 0 0 0!important
    }
    .g69652 .azw915 .a3r27y .bz803k{
        display: none!important;
    }
    .v80y78 .container .row .col-md-3:last-child{
        display: none;
    }
    .o747gy, .oq5t79{
        display: block !important;
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 130px !important;
        position: relative !important;
    }
    .eg5468{
        float: none !important;
        overflow: hidden !important;
        padding-bottom: 170px !important;
        position: relative !important;
    }
    .o747gy .s40258, .e2251v, .q843vr{
        background-position: 0 !important;
        height: 100% !important;
        left: 0;
        position: absolute !important;
        top: 0;
    }
    .o747gy .s40258 .uf9065, .s288ei .s40258::before{
        height: 100%!important
    }
    .s288ei .o747gy .uf9065 .c137ai{
        font-size: 16px!important;
        line-height: 22px!important;
    }
    .s288ei .o747gy .uf9065{
        padding: 42px 32px!important;
    }
    .ad37g3{
        display: none!important;
    }
    .s288ei .na820w .xx3i47{
        height: auto!important;
        padding: 16px 0 0 0!important;
    }
    .s288ei .na820w .xx3i47 .s69j76{
        width: 100%!important;
        font-size: 14px!important;
        text-align: center!important;
    }
    .s288ei .oj1a97 .u2k9z3 .x11q7x, .s288ei .a6ft70 .u2k9z3 .x11q7x{
        width: 100%!important;
        font-size: 16px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .v7d78o, .s288ei .oj1a97 .u2k9z3 .h8697j, .s288ei .a6ft70 .u2k9z3 .h8697j, .s288ei .x195er .c3ev52 .y01k42, .s288ei .x195er .qk056a .j974ze{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .x195er .y931x3 .q1779z, .s288ei .x195er .c3ev52 .n8037e{
        width: 100%!important;
        font-size: 15px!important;
        text-align: center!important;
        line-height: 23px;
    }
    .s288ei .x195er .y931x3 .tkv048{
        font-size: 13px!important;
        line-height: 21px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .oau798{
        font-size: 13px!important;
        line-height: 22px!important;
        text-align: center!important;
    }
    .s288ei .na820w .xx3i47 .e114dj{
        max-width: 100%!important;
        height: 42px!important;
        line-height: 42px!important;
        font-size: 13px!important;
    }
    .s288ei .i84t5x .container .row .col-md-2{
        width: 50%!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t{
        text-align: center!important;
    }
    .s288ei .i84t5x .m37p4r .ue641t img{
        max-width: 100px!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0{
        height: auto!important;
    }
    .s288ei .oj1a97 .g3285g .vv75g0, .s288ei .oj1a97 .g3285g .f4ab27{
        margin: 24px 0 0 0!important
    }
    .s288ei .o711dy .oq5t79 .q843vr::before{
        font-size: 16px!important;
        line-height: 22px!important;
        padding: 42px 32px !important
    }
    .s288ei .a6ft70 .col-md-3{
        width: 50%!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o{
        height: 128px!important;
    }
    .s288ei .a6ft70 .q3412g .m4q81o .b4e26g{
        font-size: 20px!important;
    }
    .s288ei .a6ft70 .q3412g .kv554t{
        font-size: 14px!important;
        line-height: 22px!important;
    }
    .s288ei .a6ft70 .offset-md-3{
        margin-left: 0!important;
    }
    .s288ei .x195er .c3ev52, .s288ei .x195er .qk056a{
        height: auto!important
    }
    .s288ei .x195er .qk056a, .s288ei .x195er .y931x3{
        margin: 24px 0 0 0!important;
        padding: 24px 24px!important
    }
    .s288ei .x195er .y931x3{
        height: auto!important;
    }
    .s288ei .x195er .fu22h2{
        margin: 0!important;
        padding: 0!important
    }
    .s288ei .a443b9 .b1935l .x8o716{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .s288ei .a443b9 .b1935l .x8o716 .b46n42, .s288ei .a443b9 .b1935l .x8o716 .zbh982{
        text-align: center;
    }
    .s288ei .a443b9 .b1935l{
        margin-bottom: 16px!important;
    }
    .s288ei .o2gk67{
        padding: 24px 12px!important
    }
    .s288ei .o2gk67 .s7d04i .nk96w3{
        font-size: 13px!important;
    }
    .s288ei .o2gk67 .s7d04i .e74861{
        margin: 24px 0 0 0!important;
    }
    .g69652 .azw915 .a3r27y .r8038t .m1c5x2{
        text-align: center!important;
    }
    .fu22h2:nth-child(12){
        margin-left: 0!important;
    }
}